import { Component, OnInit } from '@angular/core';
import { XappOnboarderService } from '../services/xapp-onboarder/xapp-onboarder.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'rd-qo-s',
  templateUrl: './qo-s.component.html',
  styleUrls: ['./qo-s.component.scss']
})
export class QoSComponent implements OnInit {
  header: any;
  value:string = '';
  displayedColumns: string[] = ['gNodeB', 'cellId', 'ueId', 'status', 'gbr'];
  dataSource = new MatTableDataSource<any>([]);

  constructor(private xappOnboarderService: XappOnboarderService) { }

  ngOnInit(): void {
    this.getQosData();
  }

  cellData(value: any) {
    console.log(value, "cell value");
    this.value = '';
  }

  getQosData() {
    this.xappOnboarderService.getQosData().subscribe((res: any) => {
      this.dataSource.data = JSON.parse(res);
      let data = this.dataSource.data.map(x => {
        if (x.status === 0) {
          x.status = "Accepted"
        }
        if (x.status === 1) {
          x.status = "Rejected"
        }
        const createdDate = new Date(x.createdAt);
        const currentDate = new Date(); 
        const differenceInMs = currentDate.getTime() - createdDate.getTime();    // Calculate the difference in milliseconds
        const differenceInMinutes = differenceInMs / (1000 * 60);    // Convert the difference to minutes
        if (differenceInMinutes > 15) {
           x.status = "Inactive";  // If more than 15 minutes, return "Inactive"
        } 
      })
    })
  }

}
