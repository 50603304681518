/*-
 * ========================LICENSE_START=================================
 * O-RAN-SC
 * %%
 * Copyright (C) 2019 AT&T Intellectual Property
 * %%
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ========================LICENSE_END===================================
 */

 // Angular
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ChartsModule } from 'ng2-charts';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MaterialDesignFrameworkModule } from 'angular6-json-schema-form';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { HighchartsChartModule } from 'highcharts-angular';




// RD components
import { AppControlComponent } from './app-control/app-control.component';
import { CatalogComponent } from './catalog/catalog.component';
import { ConfirmDialogComponent } from './ui/confirm-dialog/confirm-dialog.component';
import { ControlComponent } from './control/control.component';
import { ErrorDialogComponent } from './ui/error-dialog/error-dialog.component';
import { FooterComponent } from './footer/footer.component';
import { OnboardComponent } from './onboard/onboard.component';
import { LoadingDialogComponent } from './ui/loading-dialog/loading-dialog.component';
import { RdRoutingModule } from './rd-routing.module';
import { RdComponent } from './rd.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';
import { LoginComponent } from './login/login.component';
import { SpinnerComponent } from './spinner/spinner/spinner.component';

// RD services
import { AppMgrService } from './services/app-mgr/app-mgr.service';
import { DashboardService } from './services/dashboard/dashboard.service';
import { E2ManagerService } from './services/e2-mgr/e2-mgr.service';
import { ErrorDialogService } from './services/ui/error-dialog.service';
import { InstanceSelectorService } from './services/instance-selector/instance-selector.service';
import { UiService } from './services/ui/ui.service';
import { XappOnboarderService } from './services/xapp-onboarder/xapp-onboarder.service';
import { DeployDialogComponent } from './ui/deploy-dialog/deploy-dialog.component';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuardServiceService } from './services/auth-guard/auth-guard-service.service';
import {Interceptor} from './interceptor';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { AlarmComponent } from './alarm/alarm.component';
import { HomeComponent } from './home/home.component';
import { GnblistComponent } from './gnblist/gnblist.component';
import { AppConfigServiceService } from './services/appconfig/app-config-service.service';
import { TopologyComponent } from './topology/topology/topology.component';
import { UeComponent } from './ue/ue.component';
import { CellComponent } from './cell/cell.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { DetailsComponent } from './details/details.component';
import { GnbInformationComponent } from './gnb-information/gnb-information.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { RicInfoComponent } from './ric-info/ric-info.component';
import { OverviewComponent } from './overview/overview.component';
import { QoSComponent } from './qo-s/qo-s.component';
// import { NgApexchartsModule } from 'ng-apexcharts';

const appInitializerFn = (appConfig: AppConfigServiceService) => {
  return () => {
      return appConfig.loadAppConfig();
  }
};

@NgModule({
  declarations: [
    AppControlComponent,
    CatalogComponent,
    ConfirmDialogComponent,
    ControlComponent,
    ErrorDialogComponent,
    FooterComponent,
    LoadingDialogComponent,
    RdComponent,
    SidenavListComponent,
    OnboardComponent,
    DeployDialogComponent,
    LoginComponent,
    LayoutComponent,
    SpinnerComponent,
    AlarmComponent,
    HomeComponent,
    GnblistComponent,
    TopologyComponent,
    UeComponent,
    CellComponent,
    SideBarComponent,
    DetailsComponent,
    GnbInformationComponent,
    SubscriptionComponent,
    RicInfoComponent,
    OverviewComponent,
    QoSComponent
  ],
  imports: [
    HighchartsChartModule,
    BrowserModule,
    BrowserAnimationsModule,
    // ChartsModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MaterialDesignFrameworkModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
	MatMenuModule,
    MDBBootstrapModule.forRoot(),
    RdRoutingModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule.forRoot({
      showForeground:true,
    })
  ],
  exports: [
    SideBarComponent,
    ErrorDialogComponent,
    FormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatTabsModule,
	MatMenuModule
  ],
  entryComponents: [
    ConfirmDialogComponent,
    ErrorDialogComponent,
    LoadingDialogComponent,
  ],
  providers: [
    AppMgrService,
    DashboardService,
    E2ManagerService,
    ErrorDialogService,
    InstanceSelectorService,
    UiService,
    XappOnboarderService,
    AuthGuardServiceService,
    
    { 
      provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi:true,
      
    },
    {provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    AppConfigServiceService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigServiceService]
  },
  

  ],
  bootstrap: [RdComponent]
})
export class RdModule { }


